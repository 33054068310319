import { ref, type Ref } from 'vue'
import useChatStore from '@/stores/chat/chat.store'
import type { Chat } from '@papershift/api/src/chat'

export default function useChatEdit(chat: Ref<Chat>) {
  const chatStore = useChatStore()
  const isEditingChat = ref(false)

  function setEditingChat(editing: boolean) {
    isEditingChat.value = editing
  }

  function refetchChat() {
    // it's important to close the edit modal first,
    // because ChatEdit component has a watcher, which
    // will trigger a refetch of the chat
    setEditingChat(false)

    chatStore.fetchChatByContext(chat.value.context_id, chat.value.context_type)

    if (chatStore.chats.length) {
      chatStore.fetchChats()
    }
  }

  return {
    isEditingChat,
    setEditingChat,
    refetchChat,
  }
}
