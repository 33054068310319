import { watch, ref, type Ref } from 'vue'
import useChatStore from '@/stores/chat/chat.store'
import type { Chat } from '@papershift/api/src/chat'

export default function useChatEdit(chatData: Ref<Partial<Chat> | null>) {
  const chat = ref<Partial<Chat>>({})
  const { getChatByContext } = useChatStore()

  // This watcher triggers when chat name changes
  watch(
    () => chatData.value,
    async (context) => {
      if (context) {
        const result = await getChatByContext(
          context.context_id!,
          context.context_type!
        )
        chat.value = result!
      } else {
        chat.value = {}
      }
    }
  )

  return { chat }
}
