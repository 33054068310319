<script lang="ts">
type FieldAppearance = 'default' | 'borderless'

export type Props = {
  modelValue?: string | number | File
  modelModifiers?: {
    number?: boolean
  }
  id: string
  errorId?: string | string[]
  type?: string
  appearance?: FieldAppearance
  as?: string
  inputmode?: string
  pattern?: string
  label: string
  placeholder?: string
  validationRules?: RuleExpression<any>
  tabindex?: number
  disabled?: boolean
  autocomplete?: string
  clearButton?: boolean
  maxlength?: number
  rows?: number
}
</script>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { type RuleExpression, Field, ErrorMessage } from 'vee-validate'
import { XMarkIcon } from './icons'

const props = withDefaults(defineProps<Props>(), {
  type: 'text',
  appearance: 'default',
  modelModifiers: () => ({}),
  autocomplete: 'off',
  clearButton: true,
  rows: 4,
})
const emit = defineEmits<{
  'update:model-value': [value: string | number | File]
}>()

const errorIds = computed(() => {
  const id = props.errorId ?? props.id
  return Array.isArray(id) ? id : [id]
})
const internalValue = ref(props.modelValue)
const fieldClass = computed(() => {
  if (props.appearance === 'borderless') {
    return 'pl-0 focus:ring-0 hover:bg-gray-100'
  }

  return 'ring-1 focus:ring-2 shadow-sm'
})

watch(
  () => props.modelValue,
  (val) => {
    internalValue.value = val
  }
)

function onInput(val: string | number) {
  internalValue.value = val
  emit('update:model-value', val)
}

let tIndex = props.tabindex

/* v8 ignore start */
if (navigator.userAgent.match(/(iPhone|iPod|iPad)/i)) {
  tIndex = undefined
}
/* v8 ignore stop */
</script>

<template>
  <div>
    <label :for="id" class="block text-sm font-medium leading-6 text-gray-900">
      {{ label }}
    </label>

    <div class="relative mt-1">
      <Field
        :id="id"
        :as="as"
        :name="id"
        :type="type"
        :inputmode="inputmode"
        :pattern="pattern"
        :model-value="internalValue"
        :model-modifiers="modelModifiers"
        :placeholder="placeholder"
        :rules="validationRules"
        :tabindex="tIndex"
        :disabled="disabled"
        :autocomplete="autocomplete"
        :maxlength="maxlength"
        :rows="rows"
        class="block w-full rounded-md border-0 py-1.5 pr-8 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:ring-slate-600 text-sm leading-6 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200"
        :class="fieldClass"
        @update:model-value="onInput($event)"
      />
      <button
        v-if="clearButton && internalValue && type !== 'file'"
        class="absolute inset-y-0 right-0 flex items-start top-2"
        type="button"
        @click="onInput('')"
      >
        <XMarkIcon class="h-5 w-5 mr-2 text-gray-400 cursor-pointer" />
      </button>

      <ErrorMessage
        v-for="errId in errorIds"
        :key="errId"
        :name="errId"
        class="text-sm text-pink-800"
      />
    </div>
  </div>
</template>
