<script setup lang="ts">
import { defineProps, defineEmits, toRef } from 'vue'
import { useI18n } from '@papershift/locale/src/i18n'
import useActionTracker from '@papershift/action-status/src/action-tracker'
import { notify } from '@papershift/ui/src/Notifier'
import useModalToggler from '@papershift/ui/src/utils/use-modal-toggler'
import Modal from '@papershift/ui/src/Modal.vue'
import useChatStore from '@/stores/chat/chat.store'
import type { Chat } from '@papershift/api/src/chat'
import useChatEdit from './composables/use-chat-edit'

const { t } = useI18n()

const props = defineProps<{
  chatData: Partial<Chat> | null
}>()
const emit = defineEmits<{
  close: []
  updated: []
}>()

const $actions = useActionTracker({ updateChat: 'updateChat' })

const { isModalActive, toggleModal, onModalClose, modalRemovedHandler } =
  useModalToggler(toRef(props, 'chatData'))

onModalClose(() => emit('close'))

const { updateChat } = useChatStore()

const { chat } = useChatEdit(toRef(props, 'chatData'))

async function submit() {
  await updateChat(chat.value.id!, chat.value)

  notify({
    title: t('success.update.title'),
    message: t('success.update.message'),
  })
  emit('updated')
  toggleModal(false)
}
</script>

<template>
  <Modal
    :is-active="isModalActive"
    :title="t('modal.title')"
    @close="toggleModal(false)"
    @removed="modalRemovedHandler"
  >
    <Form
      :submit-handler="submit"
      :action-status="$actions.updateChat"
      :chat-data="chat"
      @cancel-click="toggleModal(false)"
    >
      <Input
        id="chat_subject"
        :tabindex="1"
        :label="t('input.subject')"
        class="mt-4"
        :model-value="chat.subject"
        @update:model-value="chat.subject = $event"
      />

      <div
        class="flex flex-col-reverse justify-between mt-5 sm:mt-4 sm:flex-row"
      >
        <div class="sm:ml-auto sm:flex sm:flex-row-reverse">
          <Button
            id="chat_edit_submit"
            appearance="primary"
            :tabindex="97"
            type="submit"
            :label="t('button.submit')"
            class="w-full sm:ml-3 sm:w-auto"
          />

          <Button
            id="chat_form_cancel"
            appearance="secondary"
            :tabindex="98"
            type="button"
            :label="t('button.cancel')"
            class="mt-3 w-full sm:mt-0 sm:w-auto"
            @click="toggleModal(false)"
          />
        </div>
      </div>
    </Form>
  </Modal>
</template>

/* v8 ignore start */
<i18n locale="en">
button:
  submit: Submit
  cancel: Cancel
modal:
  title: Edit chat
input:
  subject: Subject
success:
  update:
    title: Success
    message: Chat has been updated
</i18n>

<i18n locale="de">
button:
  submit: Speichern
  cancel: Abbrechen
modal:
  title: Chat bearbeiten
input:
  subject: Betreff
success:
  update:
    title: Erfolgreich
    message: Chat wurde aktualisiert
</i18n>
/* v8 ignore stop */
